import { Pagination, Div, Avatar, PanelHeaderButton, Group, MiniInfoCell, Panel, RichCell, Title, Header, Progress, Card, Tabs, TabsItem, HorizontalScroll, Button, PanelHeader, PanelHeaderContent, Search } from '@vkontakte/vkui';
import { useParams, push } from '@itznevikat/router';
import React, { useEffect, useState } from 'react'
import bridge from '@vkontakte/vk-bridge'
import { Icon56BrushOutline, Icon56VideoOutline, Icon28SearchOutline } from '@vkontakte/icons';
import { Icon16Smile } from '@vkontakte/icons';
function User ({nav, setIsSpinner,token, generateToken }) {
    let { id } = useParams()

    const params = window.location.search.slice(1)
    const [products, setProducts] = useState([])
    const [user, setUser] = useState()
    const [activeTab, setActiveTab] = useState('all')
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    id = id || (new URLSearchParams(window.location.search)).get('vk_user_id')

    function num_word(value, words){  
        value = Math.abs(value) % 100; 
        let num = value % 10;
        if(value > 10 && value < 20) return words[2]; 
        if(num > 1 && num < 5) return words[1];
        if(num === 1) return words[0];
        return words[2];
    }
    const handleChange = React.useCallback((page) => {
        setCurrentPage(page);
        selectStickers(activeTab, page);
      }, [activeTab]);   
    const selectStickers = async (type=null, page=1) => {
        setIsSpinner(true)
        let packs = (await (await fetch(`https://api.not2x.ru/user/getStickers?id=${id}&filter=${type || activeTab}&page=${page}&`+params)).json()).response
        let packs_count = packs.count
        setTotalPages(Math.ceil(packs_count / 50))
        packs=packs.items
        generateProductsList(packs)
        setIsSpinner(false)
    }
    async function generateProductsList(packs) {
        packs = packs.map((e,i,a) => {
            let caption = []
            caption.push(e.price ? e.price + ' голосов' : 'бесплатно')
            e.can_purchase ? null : caption.push("эксклюзив"),
            e.is_active ? null : caption.push('отключен')
            caption = caption.join(', ')
            caption = caption[0].toUpperCase() + caption.slice(1);
            let after = (
                <div style={{display:"flex"}}>
                    {e.is_style ? <Icon56BrushOutline width={34} height={34} /> : ""}
                    {e.is_animated ? <Icon56VideoOutline width={34} height={34} /> : ""}
                </div>
            )
            return (<RichCell 
                caption={caption} 
                href={e.link} 
                target='_blank'
                key={i} 
                before={<img style={{margin:"2px",marginRight:"8px"}} height="48px" width="48px" src={'https://vk.com/sticker/2-'+e.id+'-thumb-68-0'}/>} 
                after={after}>
                    {e.title}
                </RichCell>)
        })
        setProducts(packs)

    }
    async function createUserComponent() {
        const params = window.location.search.slice(1)
        const response = await (await fetch(`https://api.not2x.ru/user/getInfo?id=${id}&reset=1&`+params)).json()
        selectStickers()
        const info = response.response.info
        const packs_count = info.user_stickers_count
        let user
        if (id != (new URLSearchParams(window.location.search)).get('vk_user_id')) {
            user = await bridge.send('VKWebAppCallAPIMethod', {method:'users.get', params:{user_ids:id,access_token:token,v:5.185,fields:"photo_100"}})
            console.log(user)
            user = user.response[0]
        } else {
            user = await bridge.send("VKWebAppGetUserInfo")
        }

        setUser(
        <div>
            <Group>
            <RichCell before={<Avatar size={96} src={user.photo_100} />}>
                <Title level='1'>
                    {user.first_name + " " + user.last_name}
                </Title>
            </RichCell>
            </Group>
            <Group header={<Header mode="secondary">Подробности</Header>}>
                <MiniInfoCell before={<Icon16Smile height={16} width={16} />}>{packs_count + " " + num_word(packs_count, ["набор", "набора", "наборов"])}</MiniInfoCell>
                <MiniInfoCell before={<Icon56BrushOutline height={16} width={16} />}>{(info.styles_count) + " " + num_word((info.styles_count), ['стиль', "стиля", "стилей"])}</MiniInfoCell>
            </Group>
            <Group>
                <Card>
                    <div style={{margin:"8px",height:"84px", justifyContent:"center", textAlign:"center", verticalAlign:"middle"}}>
                        <div>
                            {`Собрано ${info.user_stickers_count} из ${info.all_stickers_count} ${num_word((info.all_stickers_count), ['набор', "набора", "наборов"])}`}
                        </div>
                        <Div>
                            <Progress aria-labelledby="progresslabel" value={info.user_stickers_count / info.all_stickers_count * 100}/>
                        </Div>
                    </div>
                </Card>
            </Group>
        </div>
        )
        generateProductsList(response.response.stickers.items)
        setTotalPages(Math.ceil(packs_count / 50))
    }

    useEffect(() => createUserComponent(), [])
    useEffect(async () => {
        setCurrentPage(1)
        await selectStickers(activeTab, 1)
    }, [activeTab])
    // FIXME: Работоспособность табов
    
    // TODO: поместить кнопку поиска в PanelHeader
    return (
            <Panel nav={nav}>
                <PanelHeader before={<PanelHeaderButton onClick={() => push('/stickers/search')}><Icon28SearchOutline /></PanelHeaderButton>}>
                    Главная
                </PanelHeader>
                {user}
                <Group>
                    <HorizontalScroll>
                    <Tabs>
                        <TabsItem 
                            selected={activeTab === 'all'}
                            onClick={() => {
                                setActiveTab('all')
                                
                        }}
                        >
                            Все
                        </TabsItem>
                        <TabsItem 
                            selected={activeTab === 'anim'}
                            onClick={() => {
                                setActiveTab('anim')
                                
                        }}
                        >
                            Анимированные
                        </TabsItem>
                        <TabsItem 
                            selected={activeTab === 'packs'}
                            onClick={() => {
                                setActiveTab('packs')
                                
                        }}
                        >
                            Наборы
                        </TabsItem>
                        <TabsItem 
                            selected={activeTab === 'style'}
                            onClick={() => {
                                setActiveTab('style')
                                
                        }}
                        >
                            Стили
                        </TabsItem>
                        <TabsItem 
                            selected={activeTab === 'exclusive'}
                            onClick={() => {
                                setActiveTab('exclusive')
                                
                        }}
                        >
                            Эксклюзивные
                        </TabsItem>
                        <TabsItem 
                            selected={activeTab === 'unavailable'}
                            onClick={() => {
                                setActiveTab('unavailable')
                                
                        }}
                        >
                            Недостающие
                        </TabsItem>

                    </Tabs>
                    </HorizontalScroll>
                    <div>
                        {products}
                    </div>
                    {totalPages > 1 && 
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Pagination
                            currentPage={currentPage}
                            siblingCount={window.innerWidth <= 330 ? 0 : 1}
                            boundaryCount={1}
                            totalPages={totalPages}
                            onChange={handleChange}
                        />
                    </div>}
                    
                </Group>
            </Panel>
    )
}
export default (User);