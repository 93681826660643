import { Panel } from "@vkontakte/vkui";
import Lottie from "react-lottie";
import React from "react";
import animationData from './Loading.json'

function Loading ({nav}) {

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    
    return (
        <Panel nav={nav}>
            <Lottie options={defaultOptions}
            height={256}
            width={256}
            isStopped={false}
            isPaused={false}/>
        </Panel>
        
    )
}

export default Loading;