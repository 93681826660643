import { Avatar, Card, CardGrid, RichCell, Panel, PanelHeader } from '@vkontakte/vkui'
import React, { useState, useEffect } from 'react'
import bridge from '@vkontakte/vk-bridge'
import {push} from "@itznevikat/router";
function Friends({ nav, token, generateToken, setIsSpinner }) {
    const [friends, setFriends] = useState([])
    const params = window.location.search.slice(1)
    async function fetchFriends () {
        try {
            setIsSpinner(true)
            const friends_list = (await bridge.send("VKWebAppCallAPIMethod", {
                method: "friends.get",
                params: {fields: "photo_50",v:5.141,access_token:token}
            })).response.items
            const friends_ids = friends_list.map(e => e.id)
            let response = await (await fetch(`https://api.not2x.ru/user/getFriendsStickers?friends_ids=${friends_ids}&`+params)).json()
            response = await response.sort((b,a)=>a.count - b.count)
            let out = response.map(
                (e, i) =>
                <Card key={i} mode='outline' onClick={() => push(`/stickers/?id=${e.id}`)}>
                    <RichCell
                        before={<Avatar src={friends_list.find(item => item.id === e.id).photo_50}/>}
                        caption={e.count}>
                    {friends_list.find(item => item.id === e.id).first_name + ' ' + friends_list.find(item => item.id === e.id).last_name}
                    </RichCell>
                </Card>
                )
            setFriends(out)
            setIsSpinner(false)
        } catch (e) {
            if (e.error_data.error_code === 5) {
                generateToken('friends')
            await console.log(e)
        }
    }}

    useEffect(async () => await fetchFriends(), [])
    return (
            <Panel nav={nav}>
                <PanelHeader>Друзья</PanelHeader>
                    <CardGrid size='l'>
                        {friends}
                    </CardGrid>                    
            </Panel>
    )
}

export default Friends;