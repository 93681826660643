import {Panel, RichCell, Search} from '@vkontakte/vkui';
import React from 'react'
import {Icon56BrushOutline, Icon56VideoOutline} from "@vkontakte/icons";

function BaseModerate({nav}) {
    const params = window.location.search.slice(1)
    const query = React.useRef()
    const [packs, setPacks] = React.useState([])
    const onChangeSearchState = () => {
        console.log(query.current.value)
        fetchStickers()
    }
    async function fetchStickers() {
        let packs = (await (await fetch(`https://api.not2x.ru/baseStickers/get?q=${query.current.value}&`+params)).json()).response
        console.log(packs)
        packs = packs.map((e,i,a) => {
            let caption = []
            caption.push(e.price ? e.price + ' голосов' : 'бесплатно')
            e.can_purchase ? null : caption.push("эксклюзив"),
                e.is_active ? null : caption.push('отключен')
            caption = caption.join(', ')
            caption = caption[0].toUpperCase() + caption.slice(1);
            let after = (
                <div style={{display:"flex"}}>
                    {e.is_style ? <Icon56BrushOutline width={34} height={34} /> : ""}
                    {e.is_animated ? <Icon56VideoOutline width={34} height={34} /> : ""}
                </div>
            )
            return (<RichCell
                caption={caption}
                href={e.link}
                target='_blank'
                key={i}
                before={<img style={{margin:"2px",marginRight:"8px"}} height="48px" width="48px" src={'https://vk.com/sticker/2-'+e.pack_id+'-thumb-68-0'}/>}
                after={after}>
                {e.title}
            </RichCell>)
        })
        setPacks(packs)
    }

    return (
        <Panel nav={nav}>
            <PanelHeader>База стикеров</PanelHeader>
            <Search getRef={query} onChange={onChangeSearchState} />
            {packs || "Ничего не найдено"}
        </Panel>
    )
}

export default BaseModerate;