import React, { useState, useEffect} from "react";

import { Panel, Group, PanelHeader, Avatar, SimpleCell, IconButton, Input, Button, Div, PanelHeaderButton } from '@vkontakte/vkui';
import { Icon28CancelCircleOutline, Icon24Add, Icon28HideOutline } from "@vkontakte/icons";
import { push } from "@itznevikat/router";

function ModeratedTop({id, setIsSpinner}) {
    const inputRef = React.createRef()
    const [users, setUsers] = useState([])
	const loadParams = window.location.search.slice(1)
    const changeType = () => push('/chart/')
    const addUser = async () => {
        setIsSpinner(true)
        const response = await (await fetch(`https://api.not2x.ru/top/addUser?id=${inputRef.current.value}&`+loadParams)).json()
        selectUsers()
    }

    const deleteUser = async (id, index) => {
        setIsSpinner(true)
        const response = await (await fetch(`https://api.not2x.ru/top/deleteUser?id=${id}&`+loadParams)).json()
        selectUsers()
    }
	const selectUsers = async () => {
		const response = await (await fetch('https://api.not2x.ru/top/?' + loadParams)).json()
		let top = response.response.map((e, i, a) => {
			return (<SimpleCell data-id={e.user_id} disabled key={i} after={<IconButton onClick={()=>deleteUser(e.user_id, i)}><Icon28CancelCircleOutline/></IconButton>} before={<Avatar  src={e.photo} /> }>{e.name}</SimpleCell>)
		})
		setUsers(top)
        setIsSpinner(false)
	}
	useEffect(() => selectUsers(), [])
	
	return (
	<Panel id={id}>
		<PanelHeader
            before={<PanelHeaderButton onClick={changeType}><Icon28HideOutline /></PanelHeaderButton>}
        >Топ</PanelHeader>
        <Group>
            <Div>
                <Input getRef={inputRef} />
            </Div>
            <Div>
                <Button onClick={addUser} size="l" stretched mode="secondary" before={<Icon24Add />}>Добавить</Button>
            </Div>
        </Group>
		<Group>
			{users}
		</Group>
	</Panel>
	)
}

export default ModeratedTop;