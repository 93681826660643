import { Button, Group, Panel, PanelHeader } from "@vkontakte/vkui";
import {push} from "@itznevikat/router";
import React from "react";
function Moderation ({nav}) {

    return (
            <Panel nav={nav}>
                <PanelHeader>Модераторская</PanelHeader>
                <Group>
                    <Button size="l" mode="primary" stretched onClick={()=>{fetch('https://api.not2x.ru/top/update')}}>Обновить топ коллекционеров</Button>
                </Group>
                <Group>
                    <Button size="l" mode="primary" stretched onClick={()=>push('/moder/edit')}>Модерируемый топ</Button>
                </Group>

                
            </Panel>
    )
}

export default Moderation;