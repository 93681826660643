import {Match, View, Root, Epic, useLocation} from '@itznevikat/router';
import React, { useState, useEffect } from 'react'


import bridge from '@vkontakte/vk-bridge'
import User from './panels/User/User'
import Navigation from './Navigation';
import Loading from './panels/Loading/Loading';
import Moderation from './panels/Moderation/Moderation';
import Friends from './panels/Friends/Friends';
import UserSearch from './panels/User/UserSearch'
import Top from './panels/Top/Top'
import ModeratedTop from './panels/Top/ModeratedTop'
import AccessErrorPanel from "./panels/Friends/AccessErrorPanel";
import BaseModerate from "./panels/Moderation/BaseModerate";

function Routing({setIsSpinner}) {
	const [token, setToken] = useState('')
	const [isLoaded, setIsLoaded] = useState(false)
	const {pathname} = useLocation()

	useEffect(async () => {
        setIsLoaded(true)
        console.log(pathname)
		await generateToken('')
	}, [])


    bridge.subscribe(event => {
        if (!event.detail) {
            return;
        }
        switch(event.detail.type) {
            case "VKWebAppOpenCodeReaderResult":
                if (event.detail.data.result)
                    // Обработка события в случае успеха
                    console.log(event.detail.data.result);
                else
                    // Ошибка
                    break;
            case "VKWebAppOpenCodeReaderFailed":
                // Обработка события в случае ошибки
                console.log(event.detail.data.error_type, event.detail.data.error_data);
                break;
            case "VKWebAppAccessTokenReceived":
                setToken(event.detail.data.access_token)
            default:
                console.log(event.detail.type);
        }})

	const generateToken = async (scope='') => {
		bridge.send("VKWebAppGetAuthToken", {scope:scope, app_id:8060667})
	}

    return (
        <Match initialURL={'/chart'}>
            <Epic activeStory='main-root' tabbar={isLoaded && <Navigation />}>
                <Root id='main-root'>
                    <View nav='/'>
                        <Loading nav='/'/>
                    </View>
                    <View nav='/stickers' >
                        <UserSearch nav='/search' generateToken={generateToken} token={token} id={'search'} />
                        <User nav={`/`} generateToken={generateToken} token={token} setIsSpinner={setIsSpinner} />
                    </View>
                    <View nav='/chart'>
                        <ModeratedTop setIsSpinner={setIsSpinner} nav='/edit' />
                        <Top setIsSpinner={setIsSpinner} nav='/'/>
                    </View>
                    <View nav='/friends'>
                        <Friends nav={'/'} setIsSpinner={setIsSpinner} token={token} generateToken={generateToken}/>
                        <AccessErrorPanel nav={'/error'} generateToken={generateToken}/>
                    </View>
                    <View nav='/moder'>
                        <BaseModerate nav={'/edit'} />
                        <Moderation nav='/' />
                    </View>
                </Root>
            </Epic>
        </Match>
    )}
export default Routing;