import { Panel, Group, PanelHeader, Search, Cell, Avatar, Placeholder } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge'
import React, { useState } from 'react'
import { Icon56SearchOutline } from '@vkontakte/icons';
import { push } from '@itznevikat/router'
function UserSearch({nav, generateToken, token}) {
    const [users, setUsers] = useState()
    const [placeholder, setPlaceHolder] = useState(true)
    const searchRef = React.useRef()
    const onChangeSearchState = async () => {
        if(searchRef.current.value?.length >= 4){
            try {
                let response = await bridge.send('VKWebAppCallAPIMethod', {method:'users.search', params:{fields:'photo_50',v:5.131,access_token:token,q:searchRef.current.value}})
                let user_profiles = response.response.items
                setUsers(user_profiles.map((e,i,a) => (
                    <Cell onClick={() => push(`/stickers?id=${e.id}`)} key={i} before={<Avatar src={e.photo_50}/>}>{e.first_name + ' ' + e.last_name}</Cell>
                )))
                setPlaceHolder(false)
            } catch (e) {
                console.log(e)
                generateToken()
                onChangeSearchState()
            }
            
        } else {
            setUsers([])
            setPlaceHolder(true)
        }
    }
    return (
        <Panel nav={nav}>
            <PanelHeader>Поиск</PanelHeader>
            <Search getRef={searchRef} onChange={onChangeSearchState}/>
            <Group>
                {placeholder && <Placeholder
                 icon={<Icon56SearchOutline />}
                >
                    Введите имя или фамилию пользователя
                </Placeholder>}
                {users}
            </Group>
        </Panel>
    )
}

export default UserSearch;