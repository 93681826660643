import React from 'react'
import {Panel, Placeholder, PanelHeader} from "@vkontakte/vkui";
import {Icon56UsersOutline} from "@vkontakte/icons";
import {push} from "@itznevikat/router";

function AccessErrorPanel({nav, generateToken}) {
    async function tryAgain() {
        try{
            await generateToken()
            push('/friends')
        } catch {
            console.log('catch')
        }
    }
    return (
        <Panel nav={nav}>
            <PanelHeader>Ошибка</PanelHeader>
            <Placeholder
                icon={<Icon56UsersOutline />}
                header={"Произошла ошибка"}
                action={tryAgain}
            >

            </Placeholder>
        </Panel>
    )
}

export default AccessErrorPanel;