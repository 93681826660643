import { Icon28SettingsOutline, Icon28SmileOutline, Icon28UsersOutline } from '@vkontakte/icons';
import { Tabbar, TabbarItem } from '@vkontakte/vkui'
import { push, useDeserialized } from '@itznevikat/router'
import React from 'react'

function Navigation({}) {
    const { view } = useDeserialized()
    return (
        <Tabbar>
            <TabbarItem
                selected={view === "/stickers"}
                onClick={() => push("/stickers")}
                text="Стикеры">
                <Icon28SmileOutline />
            </TabbarItem>
            <TabbarItem
                selected={view === "/chart"}
                onClick={() => push("/chart")}
                text="Коллекционеры">
                <Icon28UsersOutline />
            </TabbarItem>
            <TabbarItem
                selected={view === '/friends'}
                onClick={() => {push('/friends')}}
                text='Друзья'>
                <Icon28UsersOutline />
            </TabbarItem>
            <TabbarItem 
                selected={view === '/moder'}
                onClick={() => push('/moder')}
                text='Модераторская'>
                <Icon28SettingsOutline />
            </TabbarItem>
        </Tabbar>
    )
}

export default Navigation;