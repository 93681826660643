import React, { useState } from 'react';
import { View, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, Root, Epic, Spinner, ScreenSpinner, usePlatform, VKCOM, WebviewType } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import bridge from '@vkontakte/vk-bridge'

import Routing from './Routing';

const App = () => {
	const [isSpinner, setIsSpinner] = useState(false)
	const [scheme, setScheme] = useState('bright_light')
	const platform = usePlatform()

	return (
		<ConfigProvider scheme={scheme}
			webviewType={
				platform === VKCOM ? WebviewType.INTERNAL : WebviewType.VKAPPS
				}>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={isSpinner && <ScreenSpinner size='large' />}>
						<SplitCol>
							<Routing setIsSpinner={setIsSpinner} />
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}


export default App;
