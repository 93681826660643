import React, { useState, useEffect} from "react";
import { push } from '@itznevikat/router'

import { Panel, Group, PanelHeader, Avatar, SimpleCell, PanelHeaderEdit, PanelHeaderBack, PanelHeaderButton } from '@vkontakte/vkui';
import { Icon28SettingsOutline } from "@vkontakte/icons";

function Top({nav}) {
    const [users, setUsers] = useState([])

	const changeType = () => {push('/chart/edit')}
	const loadParams = window.location.search.slice(1)
	let selectUsers = async () => {
		const response = await (await fetch('https://api.not2x.ru/top/?' + loadParams)).json()
		let top = response.response.map((e, i, a) => {
			return (<SimpleCell key={i} after={e.stickers_count} before={<Avatar  src={e.photo} /> }>{e.name}</SimpleCell>)
		})
		setUsers(top)
	}
	useEffect(() => selectUsers(), [])
	
	return (
	<Panel nav={nav}>
		<PanelHeader
			before={<PanelHeaderButton onClick={changeType}><Icon28SettingsOutline /></PanelHeaderButton>}>Топ</PanelHeader>
		<Group>
			{users}
		</Group>
	</Panel>
	)
}

export default Top;